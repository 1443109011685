import React, { lazy, Suspense } from 'react';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import './i18n.js'
import "./components/assets/css/style.css";
import "./components/assets/css/responsive.css";
import "./components/assets/css/font-awesome.min.css";
import { Authenticated } from "./components/helper";
import Loader from './components/common/loader';
const Login = lazy(() => import('./features/users/login'));

const LogOut = lazy(() => import('./features/users/logOut'));
const Categories = lazy(() => import('./features/products/productInfo'));
const Home = lazy(() => import('./pages/home'));
const Sitemaintenance = lazy(() => import('./pages/sitemaintenance'));
//const Product = lazy(() => import('./components/products'));
const Products = lazy(() => import('./features/products'));
const Checkoutfree = lazy(() => import('./features/checkout/Checkoutfree'));
const Checkoutpay = lazy(() => import('./features/checkout/Checkout'));
const Thankyou = lazy(() => import('./features/orders/thankyou'));
const Account = lazy(() => import('./features/orders/myOrders'));
const ForgotPassword = lazy(() => import('./features/users/Forgotpassword'));
const ResetPassword = lazy(() => import('./features/users/ResetPassword'));

const Myaccount = lazy(() => import('./features/users/Myaccount'));
const Terms = lazy(() => import('./components/common/terms'));
const PdfViewer = lazy(() => import('./features/products/pdfviewer.js'));

/*
import Login from './features/users/login';
import LogOut from './features/users/logOut';
import Categories from './components/categories';
import Home from './pages/home.js';
import Sitemaintenance from './pages/sitemaintenance';
import Product from './components/products';
import Checkout from './components/checkout';
import Checkoutfree from './components/checkout/Checkoutfree';
import Checkoutpay from './components/checkout/Checkout';
import Thankyou from './components/checkout/thankyou';
import Account from './components/account';
import ForgotPassword from './components/account/Forgotpassword';
import ResetPassword from './components/account/ResetPassword';
import Myaccount from './features/users/Myaccount.js';
import Terms from './components/common/terms';
import PdfViewer from './components/checkout/pdfviewer';*/




function App() {
  return (
    <Router >
      <Suspense fallback={<main className="main-section"><Loader propsClassName="load-lag" /> </main>}>
        <Routes>
          <Route path="/" element={<Authenticated />} >
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="offerinfo" element={<Checkoutfree />} />
            <Route exact path="productinfo" element={<Categories />} />
            <Route exact path="terms" element={<Terms />} />
            {/* <Route exact path="products" element={<Product />}  /> */}
            <Route path="product/:name" element={<Products />} />
            <Route exact path="checkoutpay" element={<Checkoutpay />} />
            <Route exact path="thankyou" element={<Thankyou />} />
            <Route exact path="account" element={<Account />} />
            <Route exact path="myaccount" element={<Myaccount />} />
            <Route exact path="PdfViewer" element={<PdfViewer />} />
          </Route>
          <Route exact path="sitemaintenance" element={<Sitemaintenance />} />
          <Route path="/resetpassword/:token/:userid" element={<ResetPassword />} />
          <Route exact path="forgot-password" element={<ForgotPassword />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/logout" element={<LogOut />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
