import { http } from "../helper";
import { BaseUrl } from "../common/config";
import { getStorage } from "./index";

export const addtoCart = (item, offerProduct) => {
  let qty = document.getElementById(
    "product_number_" + item.productNumber
  ).value;
  const cartItem = {
    productNumber: item.productNumber,
    productType: "Normal",
    productCategory:
      item.categoryId === "1"
        ? "PIZZA"
        : item.categoryId === "10" || item.categoryId === "13"
        ? "PASTA, RICE & NOODLE SAUCES"
        : "BURRITO",
    productDescription: item.productDescription,
    offerApplicable: offerProduct,
    productImage: item.productImage,
    qty: qty,
    itemPrice: item.PDP_Price1,
    packageSize: item.packageSize,
    itemTotal: item.PDP_Price1 * qty,
    vat: item.productTaxVal * qty,
  };
  const payload = {
    ...cartItem,
    accountCode: getStorage("accountCode"),
  };
   
  let URL = BaseUrl + "cart";
  let responseData = http
    .post(URL, payload);
  
};
