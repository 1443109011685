import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../components/helper";

export const userLogin = createAsyncThunk("users/login", async (payload) => {
  const response = await http.post("login", payload);
  return response.data;
});

export const forgotpass = createAsyncThunk(
  "users/forgotpass",
  async (payload) => {
    const response = await http.post("forgotpassword", payload);
    return response.data;
  }
);

export const passwordtoken = createAsyncThunk(
  "users/passwordtoken",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await http.post("passwordtokencheck", payload);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const passwordreset = createAsyncThunk(
  "users/passwordreset",
  async (payload) => {
    const response = await http.post("resetpassword", payload);
    return response.data;
  }
);

const initialState = {
  userData: [],
  forgotpassData: [],
  passtoken: [],
  resetpass: [],
  status: "idle",
  forgotStatus: "idle",
  error: null,
  passtokenstatus: "idle",
  passtokenerror: null,
  resetpassStatus: "idle",
  resetpassError: null,
};

const usersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    logout(state) {
      state.userData = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userData = action.payload;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(forgotpass.pending, (state) => {
        state.forgotStatus = "loading";
      })
      .addCase(forgotpass.fulfilled, (state, action) => {
        state.forgotStatus = "succeeded";
        state.forgotpassData = action.payload;
      })
      .addCase(forgotpass.rejected, (state, action) => {
        state.forgotStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(passwordtoken.pending, (state) => {
        state.passtokenstatus = "loading";
      })
      .addCase(passwordtoken.fulfilled, (state, action) => {
        state.passtokenstatus = "succeeded";
        state.passtoken = action.payload;
      })
      .addCase(passwordtoken.rejected, (state, action) => {
        state.passtokenstatus = "failed";
        state.passtokenerror = action.payload;
      })
      .addCase(passwordreset.pending, (state) => {
        state.resetpassStatus = "loading";
      })
      .addCase(passwordreset.fulfilled, (state, action) => {
        state.resetpassStatus = "succeeded";
        state.resetpass = action.payload;
      })
      .addCase(passwordreset.rejected, (state, action) => {
        state.resetpassStatus = "failed";
        state.resetpassError = action.error.message;
      });
  },
});

export const { logout } = usersSlice.actions;
export default usersSlice.reducer;
